import { FC, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useState } from "react";
import {
  getTrendingProducts,
  type TrendingProduct,
} from "utils/trendingProducts";
import Image, { StaticImageData } from "next/image";
import { LoadingTrendingProducts } from "../loading/LoadingTrendingProducts";
import Link from "next/link";

import brimonique from "@/img/Styling Foam.webp";
import arrowRight from "@/img/arrow_right_black.svg";

interface TrendingProps {
  setSearchQuery: (searchQuery: string) => void;
  handleLocationInputClick: () => void;
}

// Extends TrendingProduct, changes image_link, adds isSponsored and sponsoredLink
interface TrendingSponsored extends Omit<TrendingProduct, "image_link"> {
  image_link: string | StaticImageData;
  isSponsored?: boolean;
  sponsoredLink?: string;
}
const sponsoredProducts: TrendingSponsored[] = [
  {
    upc: "-1",
    product_name: "Bri Monique Final Touch Leave In Conditioner 3.4oz",
    image_link: brimonique,
    trending_date: "N/A",
    isSponsored: true,
    product_link:
      "https://www.brimoniquecollection.com/products/bri-monique-styling-foam-mousse-6-8oz",
    brand: "Bri Monique",
  },
  {
    upc: "-1",
    product_name: "Bri Monique Final Touch Leave In Conditioner 3.4oz",
    image_link: brimonique,
    trending_date: "N/A",
    isSponsored: true,
    product_link:
      "https://www.brimoniquecollection.com/products/bri-monique-styling-foam-mousse-6-8oz",
    brand: "Bri Monique",
  },
];

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Image
      className={`${className} color-red -ml-10 mr-10 mt-[-2.6rem] !rotate-180 rounded-full bg-red sm:ml-0 md:mt-[-5.55rem]`}
      style={{
        ...style,
        display: "block",
        backgroundImage: arrowRight,
        height: "24px",
        width: "24px",
      }}
      onClick={onClick}
      alt="Previous Arrow"
      src={arrowRight}
    />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Image
      className={`${className} -mr-6 -mt-8 sm:mr-0 md:mt-[-2.8rem]`}
      style={{
        ...style,
        display: "block",
        backgroundImage: arrowRight,
        height: "24px",
        width: "24px",
      }}
      onClick={onClick}
      alt="Next Arrow"
      src={arrowRight}
    />
  );
}

const TrendingSlider: FC<TrendingProps> = ({
  setSearchQuery,
  handleLocationInputClick,
}) => {
  const [trendingProducts, setTrendingProducts] =
    useState<TrendingSponsored[]>(null);

  useEffect(() => {
    async function fetchTrending() {
      const res =
        (await getTrendingProducts()) as unknown as TrendingSponsored[];
      res.splice(0, 0, sponsoredProducts[0]);
      res.splice(3, 0, sponsoredProducts[1]);
      setTrendingProducts(res);
    }
    fetchTrending();
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    nextArrow: <NextArrow className="md:mt-[-3rem]" />,
    prevArrow: <PrevArrow classname="" />,
    responsive: [
      {
        breakpoint: 765, // Adjust the breakpoint value as needed
        settings: {
          slidesToShow: 1, // Show one slide on mobile
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 1020, // Adjust the breakpoint value as needed
        settings: {
          slidesToShow: 1, // Show one slide on mobile
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024, // Adjust the breakpoint value as needed
        settings: {
          slidesToShow: 1, // Show one slide on mobile
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {trendingProducts && trendingProducts.length > 1
        ? trendingProducts.map((product) => (
            <TrendingProductsCard
              key={product.upc}
              product={product}
              setSearchQuery={setSearchQuery}
              handleLocationInputClick={handleLocationInputClick}
            />
          ))
        : LoadingTrendingProducts}
    </Slider>
  );
};

interface TCardProps {
  product: TrendingSponsored;
  setSearchQuery: (searchQuery: string) => void;
  handleLocationInputClick: () => void;
}

const TrendingProductsCard: FC<TCardProps> = ({
  product,
  setSearchQuery,
  handleLocationInputClick,
}) => {
  function learnMoreClick(product: TrendingSponsored) {
    setSearchQuery(product.product_name);
    handleLocationInputClick();
  }
  return (
    <div
      className="relative flex h-[min] flex-col justify-center px-2 px-6 md:h-96"
      key={product.upc}
    >
      {product.isSponsored && (
        <div className="absolute inset-3 mb-[-10rem] mt-[-.5rem] pl-4 text-sm font-light text-black">
          Sponsored
        </div>
      )}
      <div className="xxs:h-38 xxs:w-38 mx-auto flex h-36 w-44 justify-center rounded-2xl bg-white sm:h-44 sm:w-52">
        <Image
          className={`my-auto h-full max-h-[6.5rem] w-full max-w-[6.5rem] shrink-0 object-scale-down md:max-h-[7.5rem] md:max-w-[7.5rem] ${
            product.isSponsored ? "scale-150" : "scale-110"
          }`}
          src={product.image_link}
          alt={product.product_name}
          width={0}
          height={0}
          sizes="100vw"
          quality={100}
        />
      </div>
      <div className="!h-12">
        <h3 className="line-clamp-3 h-12 overflow-clip px-6 pt-2 text-base font-semibold">
          {product.product_name}
        </h3>
      </div>
      <div className="flex justify-center py-2">
        {!product.isSponsored ? (
          <button
            className="h-10 w-10/12 rounded border border-red-500 text-center font-semibold text-red-500 sm:h-12"
            onClick={() => learnMoreClick(product)}
          >
            Search
          </button>
        ) : (
          <Link
            className="z-10 flex h-10 w-10/12 items-center justify-center rounded border border-red-500 text-center align-text-top font-semibold text-red-500 md:h-12"
            href={product.sponsoredLink}
            target="_blank"
          >
            Learn More
          </Link>
        )}
      </div>
    </div>
  );
};

const TrendingProducts: FC<TrendingProps> = ({
  setSearchQuery,
  handleLocationInputClick,
}) => {
  return (
    <div className="">
      <div className="row gap-6">
        <div className="grid grid-cols-1">
          <div
            className="carousel justify-center px-6 pb-12 font-bold text-black"
            id="trendingProducts"
          >
            <div className="container mx-auto max-w-[80%] px-4 md:justify-center md:p-0">
              <TrendingSlider
                setSearchQuery={setSearchQuery}
                handleLocationInputClick={handleLocationInputClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingProducts;
