import Link from "next/link";
import { useSession } from "@supabase/auth-helpers-react";

import Image from "next/image";
import logo from "@/img/logo.png";

interface MobileMenuProps {
  setShowMobileMenu: (showMobileMenu: boolean) => void;
}

const MobileMenu = ({ setShowMobileMenu }) => {
  const session = useSession();
  return (
    <div className="fixed inset-0 z-30 bg-blue-800">
      <div className="px-6 pt-5">
        <div className="flex justify-between">
          <Link href="/" className="flex items-center">
            <Image className="mr-3" src={logo} alt="Youdle logo" height={44} />
            <span className="self-center whitespace-nowrap text-2xl font-semibold dark:text-white"></span>
          </Link>
          <div className="flex place-items-center md:order-2">
            <button
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="text-gray-500 hover:bg-gray-100 inline-flex items-center rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-701 dark:focus:ring-gray-600 md:hidden"
              aria-controls="navbar-sticky"
              aria-expanded="false"
              onClick={() => setShowMobileMenu(false)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-8"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 24L16 16M16 16L8 8M16 16L24 8M16 16L8 24"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className=" grid gap-6 py-20 pb-10 text-3xl font-bold">
          <Link
            href="https://www.facebook.com/groups/getyoudle"
            aria-current="page"
          >
            Community
          </Link>
          <Link href="https://youdle.printify.me/products">Shop</Link>
          {/* {session ? ( */}
          <Link href="https://news.youdleit.com">News</Link>
          {/* ) : ( */}
          {/* <Link href="login">Log In</Link> */}
          {/* )} */}
        </div>
        <hr />
        <div className=" grid gap-4 pt-10 text-2xl font-bold">
          {/* {session && <Link href="login">Account</Link>} */}
          <div>Notifications</div>
          <Link href="http://news.youdleit.com/">News</Link>
          <Link href="https://youdle.printify.me/products">Shop</Link>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
