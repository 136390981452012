import Link from "next/link";
import Image from "next/image";
import {} from "react";

interface BlogInfo {
  title: string;
  date: string;
  imgSrc: string;
  link: string;
  alt: string;
}

const blogInfo: BlogInfo[] = [
  {
    title: "Grocery Trends and News: Kicking Off 2025",
    date: "Jan 1, 2025",
    imgSrc:
      "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgFxzCSXZRO9mbjH35kVa2Rw3K2-O1ralcFneRsb1yXasZ6Z8O0KxOeVhTnuWqEocEFhsdt-YJcx6mW1fun5gpLiMn4D5cZmoTDcWQJg5InL9VV1sciY1h37WYFoNmFQ-6KZhdk-x1GvJjOfcxsVp7l3V_9CTrzj74QYGHip_SmyK20ia9AAgyEyKYxS7JC/s16000/grocery%20store.png",
    link: "https://news.youdleit.com/2025/01/grocery-trends-and-news-kicking-off-2025.html",
    alt: "groceries",
  },
  {
    title: "Fabuloso Recall Notice: Millions of Units Affected",
    date: "Dec 28, 2024",
    imgSrc:
      "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhW3XouZR40nnjRjHK171dipqNDADaO3SoDWIfjPvWpczivacW54JVMTrh4HzOaRuyfsD2D6rPinqLVlUZLHxnN8PWXlgCsoAX20b2RIF4fpR7BgHNMFQOpDqvcC7p-7ak1kdLW3oS8fL_OEhLSnWhd6quVtc3j9Ss6at0nqZZIGZkvkLxZTj94ea_w99w5/w200-h200/IMG_4466.png",
    link: "https://news.youdleit.com/2024/12/fabuloso-recall-notice-millions-of.html",
    alt: "fabuloso",
  },
  {
    title: "Grocery Savings Guide: 10 Ways to Save on Groceries Every Week",
    date: "Dec 15, 2024",
    link: "https://news.youdleit.com/2024/12/grocery-savings-guide-10-ways-to-save.html",
    alt: "grocery cart",
    imgSrc:
      "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg8xZL9rZJY-2k3HT2hv0m5h41NnfxQ63JD2qtIXVQnCU31QoBO-WQXZPxX1GeRur8ndu8dtQhljZsYvYBNpt28DkP7i77kjHBYJ4y7S5NHEHQzGetGVIIVnhJBF0agsEbOZvxdRVHFCeGKGreJ4cSjpZgq6Ljw_IpbwWqzik5g_TzhrCN7lClqRr2wug7H/w718-h452/Bold%20Minimalistic%20Expense%20Tracker%20Sheet%20Financial%20Planner.png",
  },
];

export function Blogs() {
  return (
    <div className="mx-auto flex w-full flex-col px-6 py-6 text-black md:px-20">
      <h1 className="w-full text-2xl font-bold md:text-3xl">Featured Blogs</h1>
      <Link
        href="blogs.youdleit.com"
        className="text-xs text-gray-600 underline"
      >
        See All
      </Link>

      <div className="col-1 mx-auto flex flex-col justify-between gap-10 py-6 sm:gap-6 md:flex-row md:gap-12">
        {blogInfo.map((blog) => (
          <Link
            href={blog.link}
            key={blogInfo.indexOf(blog)}
            className="flex h-auto w-full max-w-[300px] flex-col gap-4"
          >
            <div className="flex flex-wrap justify-center">
              <div className="min-h[100px] aspect-[3/2] overflow-hidden rounded lg:min-h-[200px]">
                <Image
                  className="h-full w-full object-cover"
                  src={blog.imgSrc}
                  alt={blog.alt}
                  width={300}
                  height={200}
                />
              </div>
            </div>

            <div className="h-full max-w-[300px] content-end px-4 md:px-2">

              <div className="text-xs font-medium text-red">
                {" "}
                {blog.date.toUpperCase()}{" "}
              </div>
              <div className="text-md text-clop line-2 font-bold">
                {" "}
                {blog.title}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Blogs;
