import Head from "next/head";
import { useState, useEffect } from "react";
import { createBrowserSupabaseClient } from "@supabase/auth-helpers-nextjs";
import { SessionContextProvider, Session } from "@supabase/auth-helpers-react";
import TagManager from "react-gtm-module";
import { useRouter } from "next/router";
import favicon from "public/img/favicon.ico";
import { Analytics } from "@vercel/analytics/react";
import { GoogleAnalytics } from "@next/third-parties/google";

import { AppProps } from "next/app";
import { Database } from "lib/supabase";
import "../styles/global.css";
import Script from "next/script";

import { Montserrat } from "next/font/google";
const montserrat = Montserrat({
  subsets: ["latin"], // Add subsets if needed
  variable: "--font-montserrat",
});

export default function App({
  Component,
  pageProps,
}: AppProps<{ initialSession: Session }>) {
  // Create a new supabase browser client on every first render.
  const [supabaseClient] = useState(() =>
    createBrowserSupabaseClient<Database>(),
  );

  // Google Tag Manager Analytics
  useEffect(() => {
    // Initialize TagManager
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID });
  }, []);

  // FB Pixel Analytics
  const router = useRouter();
  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID); // facebookPixelId
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  return (
    <SessionContextProvider
      supabaseClient={supabaseClient}
      initialSession={pageProps.initialSession}
    >
      <Head>
        <link rel="icon" href={favicon.src} sizes="any" />
        <link
          rel="icon"
          href={favicon.src}
          type="image/<generated>"
          sizes="<generated>"
        />
        <link
          rel="apple-touch-icon"
          href={favicon.src}
          type="image/<generated>"
          sizes="<generated>"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '847276557047054');
            fbq('track', 'PageView');
          `,
        }}
      />
      <Analytics />
      <div className={`${montserrat.variable} font-sans`}>
        <Component {...pageProps} />
      </div>
      <GoogleAnalytics gaId="G-M6MM021VD6" />
    </SessionContextProvider>
  );
}
