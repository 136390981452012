import Image from "next/image";
import locationImg from "@/img/location.svg";
import locationArrow from "@/img/location-arrow.svg";
import type { FC } from "react";
import type { GoogleGeocodeLocation } from "utils/googleMapsUtils";
import usePlacesAutocomplete from "use-places-autocomplete";
import useCustomAutocomplete from "hooks/useCustomAutocomplete";

interface AutocompleteProps {
  zipcode: string;
  setZipcode: (zipcode: string) => void;
}

const PlacesAutocomplete: FC<AutocompleteProps> = ({ setZipcode, zipcode }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const { handleInput, renderSuggestions, CurLocationSuggestion } =
    useCustomAutocomplete({
      setZipcode,
      setValue,
      clearSuggestions,
      data,
    });

  return (
    <div className="text-black" onKeyUp={() => null}>
      <i className="absolute p-3 pl-4">
        <Image src={locationImg} alt="" />
      </i>
      <input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={zipcode ? zipcode : "Location"}
        className=" h-12 w-full bg-transparent pl-12 placeholder:text-gray-701 focus:border-transparent focus:outline-blue"
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && (
        <div className="relative z-10">
          <div className="absolute w-full">
            <CurLocationSuggestion />
            {renderSuggestions()}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlacesAutocomplete;
